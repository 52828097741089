import React from "react";
import Logo from "./../assets/png/logo.png";
import twitterImg from "./../assets/png/twitter.png";
import solo from "./../assets/png/solo.png";
import teligram from "./../assets/png/teligram.png";
function Footer() {
  return (
    <>
      <footer className="relative max-w-[1300px] mx-auto px-4  lg:px-8 xl:px-10  md:my-20 my-10 lg:pt-10 xl:pt-20 ">
        <div className="flex md:flex-row flex-col items-center justify-between gap-y-10">
          <div className="md:max-w-[350px] lg:max-w-[500px]   ">
            <a href="#" className="flex items-center gap-1 sm:gap-2">
              <img
                src={Logo}
                alt="logo"
                className="max-w-[170px] sm:max-w-[150px]"
              />
              <span className="text-3xl sm:text-[83px] uppercase font-milk">
                {" "}
                toco
              </span>
            </a>
            <p className="my-3 text-[#6C6A6A] text-lg md:text-lg lg:text-[22px]">
              
            </p>
          </div>
          <div className="  ">
            <div className="text-center md:text-start">
              <h1 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[80px] xl:text-[109px] leading-[110%]  ">
                Socials
              </h1>
              <h4 className="text-black font-milk text-2xl sm:text-3xl lg:text-4xl xl:text-5xl uppercase  my-3 lg:my-4">
                Join the $toco community{" "}
              </h4>{" "}
              <div className="flex items-center gap-2 sm:gap-3 lg:gap-4 xl:gap-6 mt-6 sm:mt-10">
                <a
                  href=""
                  className="text-[#2B2B2B] text-lg xl:text-[22px] transition-all duration-300 ease-in-out bg-[#FFC251] px-5 lg:px-[22px] py-3 lg:py-[18px] rounded-full drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)] font-milk hover:drop-shadow-[4px_4px_0px_rgba(0,0,0,0.95)] "
                >
                  BUY ON UNISWAP
                </a>
                <a
                  href="https://x.com/tococoineth"
                  className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
                >
                  <img
                    src={twitterImg}
                    alt="img"
                    className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                  />
                </a>
                <a
                  href="https://t.me/tocodog"
                  className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
                >
                  <img
                    src={teligram}
                    alt="img"
                    className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                  />
                </a>
                <a
                  href="#"
                  className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
                >
                  <img
                    src={solo}
                    alt="img"
                    className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
