import React from "react";
import aboutImg from "./../assets/png/about.png";
import { LinkIcon } from "../helper/Icon";
function About() {
  return (
    <>
      <div
        id="About"
        className="relative max-w-[1600px] mx-auto  lg:px-8 xl:px-10 py-5 lg:py-8 z-10 mt-24"
      >
        <div className="flex lg:flex-row flex-col-reverse gap-5  about-bg lg:pe-5 py-28 it lg:items-end xl:items-center">
          <div className="max-w-[500px] lg:max-w-[890px] lg:min-w-[600px] 2xl:mb-0 -mb-10 ">
            <img src={aboutImg} alt="img" className="w-full" />
          </div>
          <div className="  lg:max-w-[552px] xl:ml-0 lg:-ml-16 px-4 sm:px-6 lg:px-0 ">
            <h1 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[80px] xl:text-[109px] leading-[110%]   ">
              <span className="text-[#FFA500] text-shadow">About </span> <br />
              Toco Dog
            </h1>
            <p className="text-[#000] text-[55px] sm:text-[65px] lg:text-[30px] xl:text-[22px] my-6">
               
Toco was the first human experimenter to become a dog. And this project, "TOCO" seeks to explore and celebrate the story of the world's earliest known a human dog.


            </p>
            <p className="text-[#000] text-[55px] sm:text-[65px] lg:text-[30px] xl:text-[22px] my-6"> 
トコは犬になった最初の人体実験者でした。そしてこのプロジェクト「TOCO」は、知られている世界最古の人間の犬の物語を探求し、祝うことを目的としています。
            </p>
            <div className="flex items-center gap-2 sm:gap-3 lg:gap-4 xl:gap-6">
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
