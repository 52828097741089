export const HamburgerIcon = () => {
  return (
    <svg
      className="max-w-[40px] max-h-[40px] sm:max-h-full sm:max-w-full"
      width="50"
      height="50"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 8.6665H12.833C13.1424 8.6665 13.4392 8.78942 13.658 9.00821C13.8768 9.22701 13.9997 9.52375 13.9997 9.83317C13.9997 10.1426 13.8768 10.4393 13.658 10.6581C13.4392 10.8769 13.1424 10.9998 12.833 10.9998H6.99967C6.69026 10.9998 6.39351 10.8769 6.17472 10.6581C5.95592 10.4393 5.83301 10.1426 5.83301 9.83317C5.83301 9.52375 5.95592 9.22701 6.17472 9.00821C6.39351 8.78942 6.69026 8.6665 6.99967 8.6665ZM15.1663 17.9998H20.9997C21.3091 17.9998 21.6058 18.1228 21.8246 18.3415C22.0434 18.5603 22.1663 18.8571 22.1663 19.1665C22.1663 19.4759 22.0434 19.7727 21.8246 19.9915C21.6058 20.2103 21.3091 20.3332 20.9997 20.3332H15.1663C14.8569 20.3332 14.5602 20.2103 14.3414 19.9915C14.1226 19.7727 13.9997 19.4759 13.9997 19.1665C13.9997 18.8571 14.1226 18.5603 14.3414 18.3415C14.5602 18.1228 14.8569 17.9998 15.1663 17.9998ZM6.99967 13.3332H20.9997C21.3091 13.3332 21.6058 13.4561 21.8246 13.6749C22.0434 13.8937 22.1663 14.1904 22.1663 14.4998C22.1663 14.8093 22.0434 15.106 21.8246 15.3248C21.6058 15.5436 21.3091 15.6665 20.9997 15.6665H6.99967C6.69026 15.6665 6.39351 15.5436 6.17472 15.3248C5.95592 15.106 5.83301 14.8093 5.83301 14.4998C5.83301 14.1904 5.95592 13.8937 6.17472 13.6749C6.39351 13.4561 6.69026 13.3332 6.99967 13.3332Z"
        fill="#FFA500"
      />
    </svg>
  );
};
export const CrossIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63 8.47C5 9.36667 5 12.0333 5 17.3617V19.985C5 29.3817 12.065 33.9433 16.4983 35.8783C17.7 36.4033 18.3017 36.6667 20 36.6667C21.7 36.6667 22.3 36.4033 23.5017 35.8783C27.935 33.9417 35 29.3833 35 19.985V17.3617C35 12.0317 35 9.36667 34.37 8.47C33.7417 7.575 31.2367 6.71667 26.225 5.00167L25.27 4.675C22.6583 3.78 21.3533 3.33334 20 3.33334C18.6483 3.33334 17.3417 3.78 14.73 4.675L13.775 5C8.76333 6.71667 6.25833 7.575 5.63 8.47ZM16.7167 14.95C16.6022 14.8272 16.4642 14.7287 16.3109 14.6604C16.1576 14.592 15.992 14.5553 15.8242 14.5523C15.6564 14.5494 15.4897 14.5803 15.334 14.6431C15.1784 14.706 15.037 14.7996 14.9183 14.9183C14.7996 15.037 14.706 15.1784 14.6431 15.334C14.5803 15.4897 14.5494 15.6564 14.5523 15.8242C14.5553 15.992 14.592 16.1576 14.6604 16.3109C14.7287 16.4642 14.8272 16.6022 14.95 16.7167L18.2333 20L14.95 23.2833C14.8272 23.3978 14.7287 23.5358 14.6604 23.6891C14.592 23.8424 14.5553 24.008 14.5523 24.1758C14.5494 24.3436 14.5803 24.5104 14.6431 24.666C14.706 24.8216 14.7996 24.963 14.9183 25.0817C15.037 25.2004 15.1784 25.294 15.334 25.3569C15.4897 25.4197 15.6564 25.4506 15.8242 25.4477C15.992 25.4447 16.1576 25.408 16.3109 25.3396C16.4642 25.2713 16.6022 25.1728 16.7167 25.05L20 21.7667L23.2833 25.05C23.5203 25.2708 23.8337 25.391 24.1575 25.3853C24.4814 25.3796 24.7903 25.2484 25.0194 25.0194C25.2484 24.7903 25.3796 24.4814 25.3853 24.1575C25.391 23.8337 25.2708 23.5203 25.05 23.2833L21.7667 20L25.05 16.7167C25.1728 16.6022 25.2713 16.4642 25.3396 16.3109C25.408 16.1576 25.4447 15.992 25.4477 15.8242C25.4506 15.6564 25.4197 15.4897 25.3569 15.334C25.294 15.1784 25.2004 15.037 25.0817 14.9183C24.963 14.7996 24.8216 14.706 24.666 14.6431C24.5103 14.5803 24.3436 14.5494 24.1758 14.5523C24.008 14.5553 23.8424 14.592 23.6891 14.6604C23.5358 14.7287 23.3978 14.8272 23.2833 14.95L20 18.2333L16.7167 14.95Z"
        fill="#FFA500"
      />
    </svg>
  );
};
export const LinkIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M12.7583 7.74186C15.0922 10.0781 15.0601 13.8238 12.7724 16.1243C12.7681 16.129 12.763 16.134 12.7583 16.1387L10.1333 18.7637C7.81807 21.079 4.05131 21.0787 1.73643 18.7637C-0.578809 16.4489 -0.578809 12.6817 1.73643 10.3669L3.18588 8.9174C3.57025 8.53303 4.23221 8.7885 4.25205 9.3317C4.27736 10.024 4.4015 10.7195 4.63057 11.3911C4.70815 11.6185 4.65272 11.8701 4.48279 12.04L3.97158 12.5512C2.87682 13.646 2.84248 15.4286 3.92647 16.534C5.02115 17.6504 6.82045 17.657 7.92354 16.554L10.5485 13.9294C11.6497 12.8281 11.6451 11.0482 10.5485 9.95162C10.404 9.80732 10.2583 9.69521 10.1446 9.61689C10.0641 9.56163 9.99768 9.48833 9.95056 9.40284C9.90344 9.31734 9.87696 9.22202 9.87322 9.12447C9.85775 8.7117 10.004 8.28635 10.3302 7.96018L11.1526 7.13772C11.3683 6.92205 11.7066 6.89557 11.9567 7.0701C12.2431 7.27008 12.5113 7.49486 12.7583 7.74186ZM18.2636 2.23631C15.9487 -0.0786133 12.1819 -0.0789258 9.8667 2.23631L7.2417 4.86131C7.23701 4.866 7.23193 4.87107 7.22764 4.87576C4.9399 7.17623 4.90783 10.9219 7.2417 13.2582C7.48868 13.5052 7.75692 13.7299 8.0433 13.9299C8.29338 14.1044 8.63174 14.0779 8.84736 13.8623L9.66979 13.0398C9.99596 12.7137 10.1422 12.2883 10.1267 11.8755C10.123 11.778 10.0965 11.6827 10.0494 11.5972C10.0023 11.5117 9.93584 11.4384 9.85537 11.3831C9.74162 11.3048 9.596 11.1927 9.45143 11.0484C8.35482 9.95178 8.35022 8.17186 9.45143 7.07064L12.0764 4.44603C13.1795 3.34295 14.9788 3.34959 16.0735 4.46596C17.1575 5.57143 17.1232 7.354 16.0284 8.44877L15.5172 8.95998C15.3472 9.1299 15.2918 9.38146 15.3694 9.60889C15.5985 10.2805 15.7226 10.976 15.7479 11.6683C15.7678 12.2115 16.4297 12.467 16.8141 12.0826L18.2635 10.6331C20.5788 8.31834 20.5788 4.55115 18.2636 2.23631Z"
          fill="#2B2B2B"
        />
      </svg>
    </>
  );
};
