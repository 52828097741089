import React from "react";
import HowBuy from "./../assets/png/how-tobuy.png";

function HowToBuy() {
  return (
    <>
      <div className="relative">
        <div className="relative max-w-[1300px] mx-auto px-4  lg:px-8 xl:px-10 py-5 lg:py-8 z-10 mt-10 md:mt-24">
          <div className="flex lg:flex-row flex-col items-center gap-4">
            <div className="lg:max-w-[500px] xl:max-w-[600px]">
              <h1 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[80px] xl:text-[109px] leading-[110%]">
                hot to buy
              </h1>
              <p className="text-base sm:text-lg text-[#000] py-4 lg:py-6">
                To buy $TOCO dog, download Metamask app, purchase $ETH from an
                exchange and send it to your wallet then buy $TOCO onUniswap.
              </p>
              <div className="flex">
                <a
                  href=""
                  className="text-[#2B2B2B] text-lg xl:text-[22px] transition-all duration-300 ease-in-out bg-[#FFC251] px-5 lg:px-[22px] py-3 lg:py-[18px] rounded-full drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)] font-milk hover:drop-shadow-[4px_4px_0px_rgba(0,0,0,0.95)] flex items-center "
                >
                  Get $TOCO
                </a>
              </div>
            </div>
            <div className="max-w-[667px]">
              <img src={HowBuy} alt="icon" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToBuy;
