import React, { useEffect, useState } from "react";

import Logo from "./../assets/png/logo.png";
import { headerLinks } from "../helper/Helper";
import { HamburgerIcon } from "../helper/Icon";
import MobileHeader from "./MobileHeader";
// import { headerLinks, mediaLinks } from "../helper/Helper";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen]);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`fixed top-0 w-full z-50   font-milk ${
          isScrolled
            ? ` transition-all duration-300 ease-in-out py-3 lg:py-5 bg-white  `
            : `  py-5 lg:py-8 `
        }`}
      >
        <div className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 z-10  ">
          <div className="flex justify-between items-center">
            <a href="#" className="flex items-center gap-1 sm:gap-2">
              <img
                src={Logo}
                alt="logo"
                className="max-w-[50px] sm:max-w-[68px]"
              />
              <span className="text-3xl sm:text-[40px] uppercase "> toco /-/ 人間の犬</span>
            </a>
            <div className="items-center gap-x-4 md:gap-x-6 lg:gap-x-8 xl:gap-x-14 hidden md:flex ml-auto">
              {headerLinks.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="text-[#404040] text-lg lg:text-2xl transition-all duration-300 ease-in-out hover:text-[#FFA500] font-normal"
                >
                  {item.name}
                </a>
              ))}
              <a
                href="https://t.me/tocodog"
                className="text-black text-lg lg:text-2xl transition-all duration-300 ease-in-out hover:text-[#FFA500] hover:border-[#FFA500] hover:shadow-xl   border-2 border-black px-5 lg:px-[22px] py-3 lg:py-[18px] rounded-full  "
              >
                Join Telegram
              </a>
            </div>
            <div className="md:hidden">
              <button className="" onClick={toggleOpen}>
                <HamburgerIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-white lg:bg-transparent transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <MobileHeader toggleOpen={toggleOpen} />
      </div>
    </>
  );
};

export default Header;
