import "./App.css";
import Header from "./components/common/Header";
import Hero from "./components/common/Hero";
import About from "./components/common/About";
import HowToBuy from "./components/common/HowToBuy";
import Tokenomics from "./components/common/Tokenomics";
import Footer from "./components/common/Footer";

function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <HowToBuy />
      <Tokenomics />
      <Footer />
    </>
  );
}

export default App;
