import React from "react";
import twitterImg from "./../assets/png/twitter.png";
import solo from "./../assets/png/solo.png";
import teligram from "./../assets/png/teligram.png";
import heroImg from "./../assets/png/hero1.png";
import heroImg2 from "./../assets/png/hero-2.png";
function Hero() {
  return (
    <>
      <div
        id="home"
        className="relative max-w-[1340px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 py-5 lg:py-8 z-10 mt-24  sm:mt-28 lg:mt-40 xl:min-h-[80vh] flex flex-col justify-center"
      >
        <div className="flex flex-col lg:flex-row justify-between gap-y-10 gap-x-6 lg:gap-x-10 items-center">
          <div className="  lg:max-w-[552px]">
            <h1 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[80px] xl:text-[109px] leading-[110%]   ">
              <span className="text-[#FFA500] text-shadow">Toco</span> 
            </h1>
            <h3 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[30px] xl:text-[59px] leading-[110%]">人間の犬</h3>
            <p className="text-[#000] text-[55px] sm:text-[65px] lg:text-[30px] xl:text-[29px] my-6">
              {" "}
               Toco was originally a human, but he decided to transform himself into a dog. And now it is known in Japan as Ningen no inu. 
            </p>
            <div className="flex items-center gap-2 sm:gap-3 lg:gap-4 xl:gap-6">
              <a
                href=""
                className="text-[#2B2B2B] text-lg xl:text-[22px] transition-all duration-300 ease-in-out bg-[#FFC251] px-5 lg:px-[22px] py-3 lg:py-[18px] rounded-full drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)] font-milk hover:drop-shadow-[4px_4px_0px_rgba(0,0,0,0.95)] "
              >
                BUY ON UNISWAP
              </a>
              <a
                href="https://x.com/tococoineth"
                className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
              >
                <img
                  src={twitterImg}
                  alt="img"
                  className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                />
              </a>
              <a
                href="https://t.me/tocodog"
                className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
              >
                <img
                  src={teligram}
                  alt="img"
                  className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                />
              </a>
              <a
                href=""
                className="rounded-full transition-all duration-300 ease-in-out hover:drop-shadow-[2px_2px_0px_rgba(0,0,0,0.95)]  "
              >
                <img
                  src={solo}
                  alt="img"
                  className="w-10 sm:w-11 xl:w-[52px] h-auto object-cover"
                />
              </a>
            </div>
          </div>
          <div className="max-w-[350px] md:max-w-[450px] lg:max-w-[634px] relative flex flex-col justify-center items-center">
            <div className="xl:p-20 md:p-16 p-10">
              <img src={heroImg} alt="img" className="w-full" />
            </div>
            <div className="absolute animate-spin rounded-full  ">
              <img src={heroImg2} alt="img" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
