import React from "react";
import coin from "./../assets/png/coin.png";
import Symbol from "./../assets/png/symbol.png";
import copy from "./../assets/png/copy.png";
import { useState } from "react";
import uniswap from "./../assets/png/uniswap.png";
import dex from "./../assets/png/dex.png";
import dexs from "./../assets/png/dexs.png";
import dexv from "./../assets/png/dexv.png";
import eth from "./../assets/png/ether.png";
import footerImg from "./../assets/png/footer.png";

function Tokenomics() {
  const [copied, setCopied] = useState(false);
  const textToCopy = "0x";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <>
      <div id="Tokenomics" className="my-12 md:my-20 lg:pt-10 relative">
        <div className="relative max-w-[1300px] mx-auto px-4  lg:px-8 xl:px-10">
          <h1 className="uppercase font-milk text-[#2B2B2B] text-[55px] sm:text-[65px] lg:text-[80px] xl:text-[109px] leading-[110%] text-center  ">
            Tokenomics{" "}
          </h1>
          <p className="text-lg sm:text-xl lg:text-[22px] text-[#6C6A6A] text-center my-2">
            
          </p>
          <div className="flex flex-wrap lg:flex-nowrap my-8 gap-7 justify-center  ">
            <div className="xl:py-6  xl:px-7 p-5 border-2 border-black backdrop-blur-[10px] bg-[rgba(255,255,255,0.50)] rounded-3xl   flex flex-col max-w-[388px] w-full">
              <div
                className="flex items-center
               justify-between pb-3 border-b-2 border-[#5151511A]"
              >
                <h4 className="text-[#3F3F3F] text-2xl lg:text-3xl xl:text-[32px] font-milk">
                  Total Supply
                </h4>
                <img src={coin} alt="icon" className="max-w-[47px] w-full" />
              </div>
              <p className="text-[#6C6A6A] text-3xl lg:text-[34px] xl:text-[33px] mt-3 lg:mt-4">
                1,000,000,000,000
              </p>
            </div>
            <div className="xl:py-6  xl:px-7 p-5 border-2 border-black backdrop-blur-[10px] bg-[rgba(255,255,255,0.50)] rounded-3xl   flex flex-col max-w-[388px] w-full">
              <div
                className="flex items-center
               justify-between pb-3 border-b-2 border-[#5151511A]"
              >
                <h4 className="text-[#3F3F3F] text-2xl lg:text-3xl xl:text-[32px] font-milk">
                  Symbol
                </h4>
                <img src={Symbol} alt="icon" className="max-w-[47px] w-full" />
              </div>
              <p className="text-[#6C6A6A] text-3xl lg:text-[34px] xl:text-[37px] mt-3 lg:mt-4">
                $TOCO{" "}
              </p>
            </div>{" "}
            <div className="xl:py-6  xl:px-7 p-5 border-2 border-black backdrop-blur-[10px] bg-[rgba(255,255,255,0.50)] rounded-3xl   flex flex-col max-w-[388px] w-full">
              <p className="text-[#6C6A6A] text-2xl lg:text-3xl xl:text-[32px] text-center">
                LP Tokens are burnt, and contract ownership is renounced.
              </p>
            </div>
          </div>
          <div className="flex items-center xl:py-6  xl:px-7 p-5 border-2 border-black backdrop-blur-[10px] bg-[rgba(255,255,255,0.50)] rounded-3xl w-full ">
            <h4 className="text-[#3F3F3F] text-2xl lg:text-3xl xl:text-[32px] font-milk">
              Token Name
            </h4>{" "}
            <div className="mx-2 sm:mx-6 w-1 h-8 bg-black opacity-10 "></div>
            <p className="text-[#6C6A6A] text-2xl lg:text-3xl  xl:text-[37px]">
              TOCO
            </p>
          </div>
          <div className="flex whitespace-nowrap items-center xl:py-6  xl:px-7 p-5 border-2 border-black backdrop-blur-[10px] bg-[rgba(255,255,255,0.50)] rounded-3xl w-full my-8 ">
            <h4 className="text-[#3F3F3F] text-2xl lg:text-3xl xl:text-[32px] font-milk whitespace-normal  sm:whitespace-nowrap">
              Token Address
            </h4>
            <div className="mx-2 sm:mx-6 w-1 h-8 bg-black opacity-10 "></div>
            <p className="text-[#6C6A6A] text-2xl lg:text-3xl xl:text-[37px]  text-ellipsis overflow-hidden  truncat">
              0x
            </p>
            <div className="ml-auto">
              <button
                className="p-1 ml-2 text-black text-lg h-7 "
                onClick={handleCopy}
              >
                {" "}
                {copied ? (
                  "Copied!"
                ) : (
                  <img src={copy} alt="icon" className="w-7 min-w-7" />
                )}
              </button>
            </div>
          </div>

          <div className="md:max-w-[450px] lg:max-w-[580px] xl:max-w-[1000px]  mx-auto lg:-mt-20">
            <img src={footerImg} alt="img" className="w-full" />
          </div>
          <div className="flex flex-wrap lg:flex-nowrap  items-center gap-3 md:gap-4 xl:gap-5 mt-10 lg:mt-16 justify-around lg:justify-between">
            <a
              href="/"
              className="border-2 border-[rgba(0,0,0,0.15)] rounded-full lg:p-5 p-3 sm:p-4 xl:p-6"
            >
              <img
                src={uniswap}
                alt="icon"
                className="max-w-[100px] sm:max-w-[150px] lg:max-w-[176px] w-full"
              />
            </a>{" "}
            <a
              href="/"
              className="border-2 border-[rgba(0,0,0,0.15)] rounded-full p-3 sm:p-4 lg:p-5 xl:p-6"
            >
              <img
                src={dex}
                alt="icon"
                className="max-w-[100px] sm:max-w-[150px] lg:max-w-[176px] w-full"
              />
            </a>{" "}
            <a
              href="/"
              className="border-2 border-[rgba(0,0,0,0.15)] rounded-full  p-3 sm:p-4 lg:p-5 xl:p-6"
            >
              <img
                src={dexs}
                alt="icon"
                className="max-w-[100px] sm:max-w-[150px] lg:max-w-[176px] w-full"
              />
            </a>{" "}
            <a
              href="/"
              className="border-2 border-[rgba(0,0,0,0.15)] rounded-full  p-3 sm:p-4 lg:p-5 xl:p-6"
            >
              <img
                src={dexv}
                alt="icon"
                className="max-w-[100px] sm:max-w-[150px] lg:max-w-[176px] w-full"
              />
            </a>{" "}
            <a
              href="/"
              className="border-2 border-[rgba(0,0,0,0.15)] rounded-full p-3 sm:p-4 lg:p-5 xl:p-6"
            >
              <img
                src={eth}
                alt="icon"
                className="max-w-[100px] sm:max-w-[150px] lg:max-w-[176px] w-full"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tokenomics;
